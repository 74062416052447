import { FC } from 'react';
import { KISForm, KISMap } from '../shared';
import { generalFormStructure } from '@/modules/project/data/formStructureGeometry.data';
import { RightsEnum } from '@/modules/project/types/project.types';
import { isDisabled } from '@/shared/utilities/checkDisabled';

export const EditProject: FC<{ auth: number }> = ({ auth }) => {
  return (
    <>
      <KISForm disableFields={isDisabled(auth, [RightsEnum.READ])} maxFormHeight="800px" formStructure={generalFormStructure} />
      <div style={{ position: 'relative' }}>
        <KISMap />
      </div>
    </>
  );
};
