import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Col, Row, Table, TableColumnsType, notification, Space, Modal } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { faEye } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyOutlined, DeleteOutlined, DragOutlined, HolderOutlined } from '@ant-design/icons';

import { PageTitle } from '@/shared/components/page-title/pageTitle';

import { getProjectList } from '@/modules/project/model';
import { ProjectType } from '@/modules/project/types/project.types';

import { Spinal } from '@/shared/components';
import styles from './home.module.scss';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { useBuildingAreaStore } from '@/shared/stores/useBuildingAreaStore';

const DragHandle = () => <HolderOutlined style={{ color: 'var(--color--grey-1)' }} />;

const SortableRow = ({ children, ...props }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
    opacity: isDragging ? 0.5 : 1,
  };

  const dragHandleProps = {
    ...attributes,
    ...listeners,
  };

  return (
    <tr {...props} ref={setNodeRef} style={style}>
      {React.Children.map(children, (child, index) => {
        if (index === 0) {
          return <td {...dragHandleProps}>{child}</td>;
        }
        return child;
      })}
    </tr>
  );
};

export const Home: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState<ProjectType[] | undefined>(undefined);
  const sensor = useSensor(PointerSensor);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    getProjectList(abortController.signal)
      .then(data => {
        setDataSource(data);
        setIsLoading(false);
      })
      .catch(() => {
        if (abortController.signal.aborted) return;
        setDataSource([]);
        notification.error({ message: t('somethingWentWrong') });
        setIsLoading(false);
      });
    return () => {
      abortController.abort();
    };
  }, [t]);

  const handleEdit = record => {
    const projectId = record.projectName.includes('(copy)') ? record.originalId : record.id;
    navigate(`/project/${projectId}`);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id && dataSource) {
      const activeIndex = dataSource.findIndex(i => i.id === active.id);
      const overIndex = dataSource.findIndex(i => i.id === over?.id);
      const newData = [...dataSource];
      const [movedItem] = newData.splice(activeIndex, 1);
      newData.splice(overIndex, 0, movedItem);
      setDataSource(newData);
    }
  };

  const handleDelete = record => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'This action cannot be undone.',
      onOk: () => {
        setDataSource(prev => prev.filter(item => item.id !== record.id));
        notification.success({ message: 'Project successfully deleted.' });
      },
    });
  };

  const handleDuplicate = record => {
    const newRecord = {
      ...record,
      id: String(Date.now()),
      projectName: `${record.projectName} (copy)`,
      isNewlyDuplicated: true,
      originalId: record.id,
    };

    const originalIndex = dataSource.findIndex(item => item.id === record.id);

    setDataSource(prev => {
      const newData = [...prev];
      newData.splice(originalIndex + 1, 0, newRecord);
      return newData;
    });

    notification.success({ message: t('importedSuccessfully') });
  };

  const columns: TableColumnsType<ProjectType> = [
    {
      title: '',
      dataIndex: 'dragHandle',
      width: 50,
      className: 'drag-handle',
      render: () => <DragHandle />,
      fixed: 'left',
    },
    {
      title: t('projects.status'),
      dataIndex: 'status',
      key: 'status',
      render: status => {
        switch (status) {
          case 0:
            return t('projects.statuses.concept');
          case 1:
            return t('projects.statuses.offer');
          case 2:
            return t('projects.statuses.processed');
          default:
            return;
        }
      },
    },
    {
      title: t('projects.projectId'),
      dataIndex: 'projectId',
      key: 'projectId',
    },
    {
      title: t('projects.generalInformation.project.projectName.title'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: 300,
      render: projectName => <b>{projectName}</b>,
    },
    {
      title: t('projects.generalInformation.location.country.title'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: t('projects.generalInformation.project.contractor.title'),
      dataIndex: 'contractor',
      key: 'contractor',
    },
    {
      title: t('projects.generalInformation.project.internalId.title'),
      dataIndex: 'internalProjectId',
      key: 'internalProjectId',
    },
    {
      title: t('projects.lastAction'),
      dataIndex: 'lastAction',
      key: 'lastAction',
    },
    {
      title: '',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            style={{ color: 'var(--color--primary-color)' }}
            icon={<CopyOutlined />}
            onClick={e => {
              e.stopPropagation();
              handleDuplicate(record);
            }}
          />
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={e => {
              e.stopPropagation();
              handleDelete(record);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Spinal initializing={false} loading={isLoading}>
        <Row gutter={[16, 48]}>
          <PageTitle title={t('projects.projects')}>
            <Link to="/new-project">
              <Button type="primary">
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
                {t('projects.newProject')}
              </Button>
            </Link>
          </PageTitle>

          <Col span={24}>
            <Row>
              <DndContext sensors={[sensor]} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={dataSource?.map(i => i.id) || []} strategy={verticalListSortingStrategy}>
                  <Table
                    components={{
                      body: {
                        row: SortableRow,
                      },
                    }}
                    rowKey="id"
                    loading={isLoading}
                    className={styles.table}
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={false}
                    onRow={record => ({
                      onClick: () => {
                        handleEdit(record);
                      },
                      className: record.projectName.includes('(copy)') ? 'fadeGold' : '',
                    })}
                  />
                </SortableContext>
              </DndContext>
            </Row>
          </Col>
        </Row>
      </Spinal>
    </Content>
  );
};
