import React from 'react';
import { Table, Slider, Typography, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './siom-table.scss';

const { Text } = Typography;

interface KPIData {
  key: string;
  indicator: string;
  unit: string;
  lowEmission: number;
  highPerformance: number;
  currentValue: number;
}

interface KPIRangeTableProps {
  data?: KPIData[];
  onChange?: (key: string, value: number) => void;
}

const defaultData: KPIData[] = [
  {
    key: '1',
    indicator: 'Energy heating used',
    unit: 'GJ/year/ha',
    lowEmission: 11480,
    highPerformance: 10275,
    currentValue: 10875,
  },
  {
    key: '2',
    indicator: 'Electrical energy used',
    unit: 'MWh/ha',
    lowEmission: 763,
    highPerformance: 1276,
    currentValue: 900,
  },
  {
    key: '3',
    indicator: 'Water used',
    unit: 'm3/ha',
    lowEmission: 8919,
    highPerformance: 9096,
    currentValue: 9000,
  },
  {
    key: '4',
    indicator: 'CO2 supplied',
    unit: 'tons/ha',
    lowEmission: 195,
    highPerformance: 324,
    currentValue: 250,
  },
  {
    key: '5',
    indicator: 'Dry fruit production',
    unit: 'kg/m2',
    lowEmission: 5.51,
    highPerformance: 6.35,
    currentValue: 5.9,
  },
  {
    key: '6',
    indicator: 'CO2 uptake (or photosynthesis)',
    unit: 'kg/m2',
    lowEmission: 10.82,
    highPerformance: 12.55,
    currentValue: 11.5,
  },
  {
    key: '7',
    indicator: 'CO2 emissions',
    unit: 'tons/ha',
    lowEmission: 1315,
    highPerformance: 1795,
    currentValue: 1500,
  },
];

const KPIRangeTable: React.FC<KPIRangeTableProps> = ({ data = defaultData, onChange }) => {
  const columns: ColumnsType<KPIData> = [
    {
      title: 'KPI Performance Indicators',
      dataIndex: 'indicator',
      key: 'indicator',
      width: '30%',
    },
    {
      title: 'Low emission',
      dataIndex: 'lowEmission',
      key: 'lowEmission',
      width: '15%',
      render: (value, record) => (
        <Text>
          {value} {record.unit}
        </Text>
      ),
    },
    {
      title: 'Range',
      key: 'range',
      width: '25%',
      render: (_, record) => {
        const min = Math.min(record.lowEmission, record.highPerformance);
        const max = Math.max(record.lowEmission, record.highPerformance);

        return (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Slider
              min={min}
              max={max}
              value={record.currentValue}
              step={0.01}
              tooltip={{
                formatter: value => `${value} ${record.unit}`,
              }}
              onChange={value => onChange?.(record.key, value)}
              style={{ margin: '10px 0' }}
            />
          </Space>
        );
      },
    },
    {
      title: 'High performance',
      dataIndex: 'highPerformance',
      key: 'highPerformance',
      width: '15%',
      render: (value, record) => (
        <Text>
          {value} {record.unit}
        </Text>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      style={{ backgroundColor: '#fff', fontSize: '12px', lineHeight: '1.2' }}
      title={() => (
        <div
          style={{
            background: '#87C540',
            padding: '10px',
            color: 'white',
            fontWeight: 'bold',
            marginTop: -16,
            marginLeft: -16,
            marginRight: -16,
          }}>
          Calculate SIOM - Indicative scenario evaluation
        </div>
      )}
    />
  );
};

export default KPIRangeTable;
