import { getHistory } from '@/modules/project/model';
import { HistoryType } from '@/modules/project/types/project.types';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const History: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [localHistoryTable, setLocalHistoryTable] = useState<HistoryType[]>([]);

  useEffect(() => {
    if (!projectId) return;
    const abortController = new AbortController();
    getHistory(projectId, abortController.signal)
      .then(res => {
        setLocalHistoryTable(res);
      })
      .catch(e => {
        if (!abortController.signal.aborted) console.error(e);
      });
    return () => {
      abortController.abort();
    };
  }, [projectId]);

  const getUniquePersons = () => {
    const uniquePersons = [...new Set(localHistoryTable.map(item => item.name))];
    return uniquePersons.map(name => ({ text: name, value: name }));
  };

  const columns: ColumnsType<HistoryType> = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => {
        return new Date(date).toLocaleString('nl');
      },
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      filters: [
        { text: 'Today', value: 'today' },
        { text: 'This week', value: 'this_week' },
        { text: 'This month', value: 'this_month' },
      ],
      onFilter: (value, record) => {
        const date = new Date(record.date);
        const today = new Date();
        if (value === 'today') {
          return date.toDateString() === today.toDateString();
        }
        if (value === 'this_week') {
          const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
          return date >= startOfWeek;
        }
        if (value === 'this_month') {
          return date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
        }
        return false;
      },
    },
    {
      title: t('activity'),
      dataIndex: 'activity',
      key: 'activity',
      filters: [
        { text: 'Activiteit 1', value: 'activity1' },
        { text: 'Activiteit 2', value: 'activity2' },
      ],
      onFilter: (value, record) => record.activity.includes(value),
      sorter: (a, b) => a.activity.localeCompare(b.activity),
    },
    {
      title: t('result'),
      dataIndex: 'result',
      key: 'result',
      sorter: (a, b) => a.result - b.result,
    },
    {
      title: t('person'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      filters: getUniquePersons(),
      onFilter: (value, record) => record.name === value,
    },
    {
      title: t('info'),
      dataIndex: 'info',
      key: 'info',
    },
  ];
  return (
    <div>
      <Table columns={columns} dataSource={localHistoryTable} pagination={false} />
    </div>
  );
};
