import { create } from 'zustand';
import { useState } from 'react';

interface BuildingArea {
  id: string;
  name: string;
  createDate: string;
  casta: boolean;
  glazenStad: boolean;
  siomCompact: boolean;
  layerBuildingArea: boolean;
  rotateGutter90Deg: boolean;
  useCAST4Input: boolean;
  HP: number | null;
  BayBeginX1: number | null;
  BayEndX2: number | null;
  GutterBeginY1: number | null;
  GutterEndY2: number | null;
  BayWidthW: number | null;
  GutterHeightH: number | null;
  OffsetX: number | null;
  OffsetY: number | null;
  SurfaceBuildingArea: string | null;
  SurfaceLayerAreaCumulative: string | null;
  HP_OffsetX: string | null;
  HP_OffsetY: string | null;
}

interface BuildingAreaState {
  selectedProjectId: string | null;
  selectedBuildingArea: BuildingArea | null;
  buildingAreas: BuildingArea[];
  editingCell: {
    id: string;
    dataIndex: string;
    value: any;
  } | null;

  // Actions
  setSelectedProject: (projectId: string) => void;
  setSelectedBuildingArea: (buildingArea: BuildingArea) => void;
  resetStore: () => void;
  setBuildingAreas: (areas: BuildingArea[]) => void;
  setEditingCell: (cell: { id: string; dataIndex: string; value: any } | null) => void;
}

const initialState = {
  selectedProjectId: null,
  selectedBuildingArea: null,
  buildingAreas: [
    {
      id: '1',
      name: 'Crop area 1',
      createDate: '2-9-2024',
      casta: true,
      glazenStad: true,
      siomCompact: true,
      layerBuildingArea: false,
      rotateGutter90Deg: false,
      useCAST4Input: true,
      HP: 1,
      BayBeginX1: 1,
      BayEndX2: 39,
      GutterBeginY1: 70,
      GutterEndY2: 205,
      BayWidthW: 4,
      GutterHeightH: 7,
      OffsetX: 0,
      OffsetY: 0,
      SurfaceBuildingArea: 31160,
      SurfaceLayerAreaCumulative: 31160,
      HP_OffsetX: 110,
      HP_OffsetY: 172.5,
    },
    {
      id: '2',
      name: 'Crop area 2',
      createDate: '2-9-2024',
      casta: true,
      glazenStad: false,
      siomCompact: false,
      layerBuildingArea: true,
      rotateGutter90Deg: true,
      useCAST4Input: false,
      HP: 1,
      BayBeginX1: 39,
      BayEndX2: 45,
      GutterBeginY1: 70,
      GutterEndY2: 205,
      BayWidthW: 4,
      GutterHeightH: 7,
      OffsetX: 0,
      OffsetY: 0,
      SurfaceBuildingArea: 4920,
      SurfaceLayerAreaCumulative: 36080,
      HP_OffsetX: 110,
      HP_OffsetY: 172.5,
    },
    {
      id: '3',
      name: 'Nursery 1.1',
      createDate: '2-9-2024',
      casta: false,
      glazenStad: true,
      siomCompact: true,
      layerBuildingArea: false,
      rotateGutter90Deg: false,
      useCAST4Input: true,
      HP: 1,
      BayBeginX1: 45,
      BayEndX2: 53,
      GutterBeginY1: 70,
      GutterEndY2: 205,
      BayWidthW: 4,
      GutterHeightH: 7,
      OffsetX: 0,
      OffsetY: 0,
      SurfaceBuildingArea: 6560,
      SurfaceLayerAreaCumulative: 42640,
      HP_OffsetX: 110,
      HP_OffsetY: 172.5,
    },
    {
      id: '4',
      name: 'Nursery 1.2',
      createDate: '2-9-2024',
      casta: false,
      glazenStad: false,
      siomCompact: false,
      layerBuildingArea: true,
      rotateGutter90Deg: true,
      useCAST4Input: false,
      HP: 0,
      BayBeginX1: 53,
      BayEndX2: 56,
      GutterBeginY1: 225,
      GutterEndY2: 50,
      BayWidthW: 4,
      GutterHeightH: 7,
      OffsetX: 0,
      OffsetY: 0,
      SurfaceBuildingArea: 600,
      SurfaceLayerAreaCumulative: 43240,
      HP_OffsetX: null,
      HP_OffsetY: null,
    },
    {
      id: '5',
      name: 'Service area',
      createDate: '4-11-2024',
      casta: true,
      glazenStad: true,
      siomCompact: false,
      layerBuildingArea: false,
      rotateGutter90Deg: false,
      useCAST4Input: true,
      HP: 2,
      BayBeginX1: 2,
      BayEndX2: 13,
      GutterBeginY1: 0,
      GutterEndY2: 142,
      BayWidthW: 5,
      GutterHeightH: 9,
      OffsetX: 0,
      OffsetY: 30,
      SurfaceBuildingArea: 7810,
      SurfaceLayerAreaCumulative: 51050,
      HP_OffsetX: 101,
      HP_OffsetY: 32.5,
    },
    {
      id: '6',
      name: 'Nursery 2',
      createDate: '4-11-2024',
      casta: true,
      glazenStad: false,
      siomCompact: true,
      layerBuildingArea: true,
      rotateGutter90Deg: true,
      useCAST4Input: false,
      HP: 0,
      BayBeginX1: 0,
      BayEndX2: 15,
      GutterBeginY1: 142,
      GutterEndY2: 44,
      BayWidthW: 5,
      GutterHeightH: 9,
      OffsetX: 0,
      OffsetY: 30,
      SurfaceBuildingArea: 3300,
      SurfaceLayerAreaCumulative: 54350,
      HP_OffsetX: null,
      HP_OffsetY: null,
    },
    {
      id: '7',
      name: 'Nursery 1 TOG',
      createDate: '4-11-2024',
      casta: true,
      glazenStad: false,
      siomCompact: false,
      layerBuildingArea: false,
      rotateGutter90Deg: false,
      useCAST4Input: true,
      HP: 0,
      BayBeginX1: 45,
      BayEndX2: 52,
      GutterBeginY1: 70,
      GutterEndY2: 40,
      BayWidthW: 4,
      GutterHeightH: 0,
      OffsetX: 0,
      OffsetY: 0,
      SurfaceBuildingArea: 1120,
      SurfaceLayerAreaCumulative: null,
      HP_OffsetX: null,
      HP_OffsetY: null,
    },
  ],
  editingCell: null,
};

export const useBuildingAreaStore = create<BuildingAreaState>(set => ({
  ...initialState,

  setSelectedProject: projectId => set({ selectedProjectId: projectId }),
  setSelectedBuildingArea: buildingArea => set({ selectedBuildingArea: buildingArea }),
  resetStore: () => set(initialState),
  setBuildingAreas: areas => set({ buildingAreas: areas }),
  setEditingCell: cell => set({ editingCell: cell }),
}));
