import React from 'react'; // Voeg deze regel toe
import { Table, Switch, Button, Modal, notification, Input } from 'antd';
import { MenuOutlined, CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useBuildingAreaStore } from '@/shared/stores/useBuildingAreaStore';
import { useState } from 'react';
import { DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// Voeg deze interface toe voor de row props
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const BuildingAreaSettings = () => {
  const { buildingAreas, setBuildingAreas } = useBuildingAreaStore();

  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [newName, setNewName] = useState<string>('');

  // Voeg sensor toe voor drag & drop
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = buildingAreas.findIndex(i => i.id === active.id);
      const overIndex = buildingAreas.findIndex(i => i.id === over?.id);

      const newAreas = [...buildingAreas];
      const [movedItem] = newAreas.splice(activeIndex, 1);
      newAreas.splice(overIndex, 0, movedItem);

      setBuildingAreas(newAreas);
    }
  };

  const columns = [
    {
      title: 'KIS Label Building Area',
      dataIndex: 'kisLabel',
      key: 'kisLabel',
      align: 'center',
      width: 80,
      className: 'drag-visible',
      render: value => (
        <div
          style={{
            margin: -8,
            padding: 8,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '5px',
            cursor: 'grab',
            fontWeight: 'bold',
          }}>
          <MenuOutlined style={{ color: '#999' }} />
          {value}
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 200,
      render: (value, record) =>
        editingKey === record.id ? (
          <div style={{ margin: -8, padding: 8, height: '100%' }}>
            <Input
              value={newName}
              onChange={e => setNewName(e.target.value)}
              onBlur={() => {
                const updatedAreas = buildingAreas.map(area => (area.id === record.id ? { ...area, name: newName } : area));
                setBuildingAreas(updatedAreas);
                setEditingKey(null);
              }}
              onPressEnter={() => {
                const updatedAreas = buildingAreas.map(area => (area.id === record.id ? { ...area, name: newName } : area));
                setBuildingAreas(updatedAreas);
                setEditingKey(null);
              }}
              style={{
                width: '100%',
                textAlign: 'left',
                border: 'none',
                backgroundColor: 'transparent',
                padding: '4px 8px',
                margin: '-4px -8px',
                height: '100%',
              }}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setNewName(value);
              setEditingKey(record.id);
            }}
            style={{
              margin: -8,
              padding: 8,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}>
            {value}
          </div>
        ),
    },
    {
      title: 'Create date',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'left',
      width: 150,
    },
    {
      title: 'CASTA',
      dataIndex: 'casta',
      key: 'casta',
      align: 'center',
      width: 120,
      render: (value, record) => (
        <Switch
          size="small"
          checked={value}
          onChange={checked => {
            const updatedAreas = buildingAreas.map(area => (area.id === record.id ? { ...area, casta: checked } : area));
            setBuildingAreas(updatedAreas);
          }}
        />
      ),
    },
    {
      title: 'SIOM compact',
      dataIndex: 'siomCompact',
      key: 'siomCompact',
      align: 'center',
      width: 120,
      render: (value, record) => (
        <Switch
          size="small"
          checked={value}
          onChange={checked => {
            const updatedAreas = buildingAreas.map(area => (area.id === record.id ? { ...area, siomCompact: checked } : area));
            setBuildingAreas(updatedAreas);
          }}
        />
      ),
    },
    {
      title: 'Glazen Stad',
      dataIndex: 'glazenStad',
      key: 'glazenStad',
      align: 'center',
      width: 120,
      render: (value, record) => (
        <Switch
          size="small"
          checked={value}
          onChange={checked => {
            const updatedAreas = buildingAreas.map(area => (area.id === record.id ? { ...area, glazenStad: checked } : area));
            setBuildingAreas(updatedAreas);
          }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 80,
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            margin: -8,
            padding: '4px 8px',
            height: '100%',
          }}>
          <Button
            type="text"
            icon={<CopyOutlined />}
            style={{
              height: '20px',
              width: '20px',
              minWidth: '20px',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#4DAA50',
            }}
            onClick={() => handleDuplicateBuildingArea(record)}
          />
          {buildingAreas.length > 1 && (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{
                height: '20px',
                width: '20px',
                minWidth: '20px',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'red',
              }}
              onClick={() => handleDeleteBuildingArea(record.id)}
            />
          )}
        </div>
      ),
    },
  ];

  const SortableRow = ({ children, ...props }: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: 'move',
      opacity: isDragging ? 0.5 : 1,
      backgroundColor: isDragging ? '#f0f0f0' : 'transparent',
    };

    return (
      <tr {...props} ref={setNodeRef} style={style}>
        {React.Children.map(children, (child, index) => {
          if (index === 0) {
            return (
              <td {...attributes} {...listeners} style={{ backgroundColor: '#fafafa' }}>
                {child}
              </td>
            );
          }
          return child;
        })}
      </tr>
    );
  };

  const handleAddBuildingArea = () => {
    const newArea = {
      id: String(Date.now()), // Maak een nieuw ID aan
      name: 'New Building Area', // Standaard naam
      createDate: new Date().toLocaleDateString('nl-NL'), // Huidige datum in NL formaat
      casta: false,
      glazenStad: false,
      siomCompact: false,
      layerBuildingArea: false,
      rotateGutter90Deg: false,
      useCAST4Input: false,
      HP: null,
      BayBeginX1: null,
      BayEndX2: null,
      GutterBeginY1: null,
      GutterEndY2: null,
      BayWidthW: null,
      GutterHeightH: null,
      OffsetX: null,
      OffsetY: null,
      SurfaceBuildingArea: null,
      SurfaceLayerAreaCumulative: null,
      HP_OffsetX: null,
      HP_OffsetY: null,
    };

    setBuildingAreas([...buildingAreas, newArea]);
    notification.success({
      message: 'Building Area Added',
      description: 'A new building area has been successfully added.',
      placement: 'topRight',
    });
  };

  const handleDeleteBuildingArea = id => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'Are you sure you want to delete this building area?',
      okType: 'danger',
      onOk: () => {
        const updatedAreas = buildingAreas.filter(area => area.id !== id);
        setBuildingAreas(updatedAreas);
        notification.success({
          message: 'Delete successful',
          description: 'The building area has been successfully deleted.',
          placement: 'topRight',
        });
      },
      style: { header: { color: 'red' } },
    });
  };

  const handleDuplicateBuildingArea = record => {
    const newArea = {
      ...record,
      id: String(Date.now()), // Maak een nieuw ID aan
      name: `${record.name} (copy)`, // Voeg "(copy)" toe aan de naam
      createDate: new Date().toLocaleDateString('nl-NL'), // Stel de huidige datum in
    };

    const originalIndex = buildingAreas.findIndex(area => area.id === record.id);

    // Voeg het duplicaat direct onder de originele rij toe
    const updatedAreas = [
      ...buildingAreas.slice(0, originalIndex + 1), // Behoud de originele rijen tot en met de gedupliceerde rij
      newArea, // Voeg het nieuwe duplicaat toe
      ...buildingAreas.slice(originalIndex + 1), // Behoud de rest van de rijen
    ];

    setBuildingAreas(updatedAreas);
    notification.success({
      message: 'Copy successful',
      description: 'The building area has been successfully copied.',
      placement: 'topRight',
    });
  };

  return (
    <div style={{ marginTop: '20px', width: '80%' }}>
      <DndContext sensors={[sensor]} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={buildingAreas.map(item => item.id)} strategy={verticalListSortingStrategy}>
          <Table
            components={{
              body: {
                row: SortableRow,
              },
            }}
            rowKey="id"
            columns={columns}
            dataSource={buildingAreas}
            size="small"
            pagination={false}
            bordered={true}
          />
        </SortableContext>
      </DndContext>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginTop: '24px', backgroundColor: '#4DAA50', borderColor: '#4DAA50' }}
        onClick={handleAddBuildingArea}>
        Add Building Area
      </Button>
    </div>
  );
};

export default BuildingAreaSettings;
