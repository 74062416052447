import React from 'react';

const DisclaimerBox: React.FC = () => (
  <div
    style={{
      border: '2px solid #d9d9d9',
      padding: '15px',
      backgroundColor: '#f5f5f5',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      margin: '20px 0',
    }}>
    <p style={{ margin: 0, fontSize: '14px' }}>
      <strong>Results are indicative.</strong>
      <br />
      Use SIOM Advanced for an accurate calculation.
    </p>
    <a
      href="https://www.hortivation.nl/nl/innovatie/siom/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: '#4DAA50',
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
      }}>
      Learn more about SIOM Advanced →
    </a>
  </div>
);

export default DisclaimerBox;
