import { useState } from 'react';
import { Table, Switch, Input } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useBuildingAreaStore } from '@/shared/stores/useBuildingAreaStore';

const ToggleTable = () => {
  const { buildingAreas, setBuildingAreas } = useBuildingAreaStore();
  const [viewMode, setViewMode] = useState('tabel'); // 'tabel' of 'input'
  const [mirrorGutter, setMirrorGutter] = useState(false);
  const [editingCell, setEditingCell] = useState<{ id: string; key: string } | null>(null);
  const [editValue, setEditValue] = useState<string | number>('');

  console.log(buildingAreas);

  const handleStartEditing = (record: any, key: string, value: any) => {
    setEditingCell({ id: record.id, key });
    setEditValue(value);
  };

  const handleSaveEdit = () => {
    // Hier zou je normaal de data updaten
    setEditingCell(null);
  };

  const handleValueChange = (value: string, record: any, key: string) => {
    // Sta alleen getallen, punt, min-teken en lege string toe
    if (!/^-?\d*\.?\d*$/.test(value) && value !== '') {
      return;
    }

    const updatedAreas = buildingAreas.map(area => {
      if (area.id === record.id) {
        return {
          ...area,
          [key]: value === '' ? null : Number(value),
        };
      }
      return area;
    });
    setBuildingAreas(updatedAreas);
  };

  const renderEditableCell = (value: any, record: any, key: string, index: number) => {
    const isCastaInputEnabled = record.useCAST4Input; // Controleer of de toggle aan staat

    // Speciale weergave voor KIS Label kolom - nu met rijnummer
    if (key === 'kisLabel') {
      return (
        <div
          style={{
            margin: -8,
            padding: 8,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}>
          {index + 1}
        </div>
      );
    }

    // Speciale weergave voor Name kolom
    if (key === 'name') {
      return <div style={{ padding: '4px 8px' }}>{value}</div>;
    }

    // Boolean waarden als switch
    if (typeof value === 'boolean') {
      return (
        <Switch
          size="small"
          checked={value}
          onChange={checked => {
            const updatedAreas = buildingAreas.map(area => {
              if (area.id === record.id) {
                return {
                  ...area,
                  [key]: checked,
                };
              }
              return area;
            });
            setBuildingAreas(updatedAreas);
          }}
        />
      );
    }

    // Numerieke input velden
    const inputStyle = {
      width: '100%',
      height: '28px',
      textAlign: 'center',
      border: 'none',
      backgroundColor: (key === 'BayWidthW' || key === 'GutterHeightH') && isCastaInputEnabled ? '#f0f0f0' : 'transparent', // Grijze achtergrond voor specifieke kolommen
      padding: '4px 8px',
      margin: '-4px -8px',
      opacity: (key === 'BayWidthW' || key === 'GutterHeightH') && isCastaInputEnabled ? 0.5 : 1, // Maak de input semi-transparant voor specifieke kolommen
      pointerEvents: (key === 'BayWidthW' || key === 'GutterHeightH') && isCastaInputEnabled ? 'none' : 'auto', // Voorkom interactie voor specifieke kolommen
    };

    return (
      <Input
        value={isCastaInputEnabled && (key === 'BayWidthW' || key === 'GutterHeightH') ? (key === 'BayWidthW' ? 2 : 0) : (value ?? '')}
        onChange={e => !(key === 'BayWidthW' || key === 'GutterHeightH') && handleValueChange(e.target.value, record, key)}
        style={inputStyle}
      />
    );
  };

  const columns = [
    {
      title: 'KIS Label Building Area',
      dataIndex: 'kisLabel',
      key: 'kisLabel',
      align: 'center',
      width: 60,
      render: (value: any, record: any, index: number) => renderEditableCell(value, record, 'kisLabel', index),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150,
      render: (value, record) => renderEditableCell(value, record, 'name'),
    },
    {
      title: 'Layer Building Area',
      dataIndex: 'layerBuildingArea',
      key: 'layerBuildingArea',
      render: (value, record) => renderEditableCell(value, record, 'layerBuildingArea'),
      align: 'center',
    },
    {
      title: 'Rotate gutter 90 deg',
      dataIndex: 'rotateGutter90Deg',
      key: 'rotateGutter90Deg',
      render: (value, record) => renderEditableCell(value, record, 'rotateGutter90Deg'),
      align: 'center',
    },
    {
      title: 'Use CASTA input W, H',
      dataIndex: 'useCAST4Input',
      key: 'useCAST4Input',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'useCAST4Input'),
    },
    {
      title: 'HP',
      dataIndex: 'HP',
      key: 'HP',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'HP'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Bay Begin</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>X1</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'BayBeginX1',
      key: 'BayBeginX1',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'BayBeginX1'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Bay End</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>X2</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'BayEndX2',
      key: 'BayEndX2',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'BayEndX2'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Gutter Begin</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>Y1</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'GutterBeginY1',
      key: 'GutterBeginY1',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'GutterBeginY1'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Gutter End</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>Y2</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'GutterEndY2',
      key: 'GutterEndY2',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'GutterEndY2'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Bay Width</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>W</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'BayWidthW',
      key: 'BayWidthW',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'BayWidthW'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Gutter Height</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>H</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'GutterHeightH',
      key: 'GutterHeightH',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'GutterHeightH'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Offset</span>{' '}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>X</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'OffsetX',
      key: 'OffsetX',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'OffsetX'),
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Offset</span>{' '}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>Y</span>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'OffsetY',
      key: 'OffsetY',
      align: 'center',
      render: (value, record) => renderEditableCell(value, record, 'OffsetY'),
    },
  ];

  const resultColumns = [
    {
      title: 'KIS Label Building Area',
      dataIndex: 'kisLabel',
      key: 'kisLabel',
      align: 'center',
      width: 60,
      render: (value: any, record: any, index: number) => <div style={{ fontWeight: 'bold' }}>{index + 1}</div>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150,
      render: (value: any) => <div>{value}</div>,
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Surface building area</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>[m²]</span>
          </div>
        </div>
      ),
      dataIndex: 'SurfaceBuildingArea',
      key: 'SurfaceBuildingArea',
      align: 'center',
      render: (value: any) => <div>{value}</div>,
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>Surface layer area</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>(Cumulative) [m²]</span>
          </div>
        </div>
      ),
      dataIndex: 'SurfaceLayerAreaCumulative',
      key: 'SurfaceLayerAreaCumulative',
      align: 'center',
      render: (value: any) => <div>{value}</div>,
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>HP Offset X</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'HP_OffsetX',
      key: 'HP_OffsetX',
      align: 'center',
      render: (value: any) => <div>{value}</div>,
    },
    {
      title: () => (
        <div style={titleStyleWithBottom}>
          <div>
            <span>HP Offset Y</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <span>[m]</span>
          </div>
        </div>
      ),
      dataIndex: 'HP_OffsetY',
      key: 'HP_OffsetY',
      align: 'center',
      render: (value: any) => <div>{value}</div>,
    },
  ];

  const titleStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '45px',
    gap: '2px',
    paddingTop: 0,
    marginTop: 0,
  };

  const titleStyleWithBottom = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '45px',
    gap: '2px',
    paddingTop: 0,
    marginTop: 0,
  };

  const getBgColor = recordName => {
    return recordName === 'Nursery 1.2' || recordName === 'Nursery 1 TOG' ? 'white' : '#f0f0f0';
  };

  return (
    <div style={{ paddingBottom: '40px' }}>
      <div className="header-controls" style={{ display: 'flex', alignItems: 'center', gap: '100px', marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px' }}>Mirror the gutter direction to North-South</span>
          <Switch size="small" checked={mirrorGutter} onChange={setMirrorGutter} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px' }}>Input</span>
          <Switch size="small" checked={viewMode === 'input'} onChange={checked => setViewMode(checked ? 'input' : 'tabel')} />
          <span style={{ marginLeft: '10px' }}>Results</span>
        </div>
      </div>
      <Table
        columns={viewMode === 'tabel' ? columns : resultColumns}
        dataSource={buildingAreas}
        scroll={{ x: true }}
        size="small"
        pagination={false}
        locale={{ emptyText: '' }}
        showHeader={true}
        bordered={true}
      />
    </div>
  );
};

export default ToggleTable;
