import { create } from 'zustand';

interface SiomResultsState {
  lastExecuted: string | null;
  setLastExecuted: (date: string) => void;
}

export const useSiomResultsStore = create<SiomResultsState>(set => ({
  lastExecuted: '2025-01-03T09:30:00',
  setLastExecuted: date => set({ lastExecuted: date }),
}));
