import React from 'react';
import styles from './siom-climate-index-info.module.scss';

const SiomClimateIndexInfo: React.FC = () => {
  return (
    <div
      style={{
        border: '2px solid #d9d9d9',
        padding: '15px',
        backgroundColor: '#f5f5f5',
        maxWidth: '600px',
        margin: '20px auto',
      }}>
      <h1 style={{ fontSize: '1.25rem', fontWeight: 'bold', lineHeight: '1.5' }}>SIOM photosynthetic Climate Index</h1>
      <h2 style={{ fontSize: '1rem', fontWeight: 'bold', lineHeight: '1.4' }}>Monthly Overview in Percentage over 12 Months</h2>
      <p className="text-xs text-gray-500 mt-4 text-center">
        Note: The index represents the percentage of maximum dry matter production, taking into account temperature, humidity, and
        CO2-inhibiting effects on photosynthesis and crop growth. The results are indicative and serve as a general guideline. For an
        accurate calculation, it is recommended to use SIOM Advanced.
      </p>
    </div>
  );
};

export default SiomClimateIndexInfo;
