import React from 'react';
import { WarningOutlined } from '@ant-design/icons';

const SiomClimateIndexInfo: React.FC = () => {
  return (
    <div
      style={{
        border: '2px solid #d9d9d9',
        padding: '25px',
        paddingBottom: '40px',
        backgroundColor: '#f5f5f5',
        maxWidth: '600px',
        margin: '0 auto 20px auto',
        position: 'relative',
      }}>
      <div
        style={{
          width: 'min-content',
          backgroundColor: '#64BD4B',
          padding: '2px 8px',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}>
        <WarningOutlined style={{ color: 'white' }} />
        <span style={{ color: 'white', fontSize: '12px', fontWeight: 'bold' }}>Disclaimer</span>
      </div>
      <div>
        <h1 style={{ fontSize: '1.25rem', fontWeight: 'bold', lineHeight: '1.5', marginTop: '10px' }}>SIOM photosynthetic Climate Index</h1>
        <h2 style={{ fontSize: '1rem', fontWeight: 'bold', lineHeight: '1.4' }}>Monthly Overview in Percentage over 12 Months</h2>
        <p className="text-xs text-gray-500 mt-4 text-center">
          <b>Note: </b> The index represents the percentage of maximum dry matter production, factoring in temperature, humidity, and C02
          inhibitory effects on photosynthesis and crop growth.
        </p>
        <br></br>
        <p style={{ margin: 0, fontSize: '14px' }}>
          <strong>Results are indicative.</strong>
          <br />
          Use SIOM Advanced for an accurate calculation.
        </p>
        <a
          href="https://www.hortivation.nl/nl/innovatie/siom/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#4DAA50',
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
          }}>
          Learn more about SIOM Advanced →
        </a>
      </div>
    </div>
  );
};

export default SiomClimateIndexInfo;
