import ReactECharts from 'echarts-for-react';
import DisclaimerBox from './siom-advanced-disclaimer';
import SurvivalChanceIndicator from './SurvivalChanceIndicator';
import SiomClimateIndexInfo from './siom-climate-index-info';
import SiomTable from './siom-table';
import { useSiomResultsStore } from '@/shared/stores/useSiomResultsStore';
import styles from './styles';

const SiomResults = () => {
  const { lastExecuted } = useSiomResultsStore();

  // Formateer de datum alleen als er een lastExecuted bestaat
  const formattedDate = lastExecuted ? new Date(lastExecuted).toLocaleString('nl-NL') : '-';

  const data = [
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Jan' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Feb' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Mrt' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Apr' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Mei' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Jun' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Jul' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Aug' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Sep' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Okt' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Nov' },
    { value: Math.round(Math.random() * (84 - 65) + 65), name: 'Dec' },
  ];

  const total = data.reduce((sum, item) => sum + item.value, 0);
  const average = (total / data.length).toFixed(2);

  const getColorByValue = (value: number): string => {
    if (value < 70) return '#FFD700'; // Lichte goudkleur
    if (value < 80) return '#FFC300'; // Goudkleur
    return '#FFB300'; // Donker goudkleur
  };

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}%',
    },
    series: [
      {
        type: 'pie',
        radius: ['30%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 2,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'inside',
          formatter: '{b}\n{c}%',
          fontSize: 12,
          color: '#4A4A4A',
        },
        labelLine: {
          show: false,
        },
        data: data.map(item => ({
          value: item.value,
          name: item.name,
          itemStyle: { color: getColorByValue(item.value) },
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
    graphic: {
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        text: Math.round(average) + '%',
        font: 'normal 30px sans-serif',
        fill: '#4A4A4A',
        align: 'center',
      },
    },
  };

  return (
    <div style={{ display: 'flex', gap: '60px' }}>
      <div style={{ flex: '1 33.333%' }}>
        <SiomClimateIndexInfo />
        <SurvivalChanceIndicator chance={75} />
      </div>
      <div style={{ flex: '2 66.666%', paddingRight: '50px' }}>
        <SiomTable />
        <p id="siom-execution-date" className="text-sm text-gray-500 mt-4 ml-auto">
          <b>Laatste uitvoering:</b> {formattedDate}
        </p>
      </div>
    </div>
  );
};

export default SiomResults;
