import React from 'react';
import { Table, Slider, Typography, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './siom-table.scss';

const { Text } = Typography;

interface KPIData {
  key: string;
  indicator: string;
  unit: string;
  lowEmission: number;
  highPerformance: number;
  currentValue: number;
}

interface KPIRangeTableProps {
  data?: KPIData[];
  onChange?: (key: string, value: number) => void;
}

const defaultData: KPIData[] = [
  {
    key: '1',
    indicator: 'Energy heating used',
    unit: 'GJ/year/ha',
    lowEmission: 11480,
    highPerformance: 10275,
    currentValue: 10875,
  },
  {
    key: '2',
    indicator: 'Electrical energy used',
    unit: 'MWh/ha',
    lowEmission: 763,
    highPerformance: 1276,
    currentValue: 900,
  },
  {
    key: '3',
    indicator: 'Water used',
    unit: 'm3/ha',
    lowEmission: 8919,
    highPerformance: 9096,
    currentValue: 9000,
  },
  {
    key: '4',
    indicator: 'CO2 supplied',
    unit: 'tons/ha',
    lowEmission: 195,
    highPerformance: 324,
    currentValue: 250,
  },
  {
    key: '5',
    indicator: 'Dry fruit production',
    unit: 'kg/m2',
    lowEmission: 5.51,
    highPerformance: 6.35,
    currentValue: 5.9,
  },
  {
    key: '6',
    indicator: 'CO2 uptake (or photosynthesis)',
    unit: 'kg/m2',
    lowEmission: 10.82,
    highPerformance: 12.55,
    currentValue: 11.5,
  },
  {
    key: '7',
    indicator: 'CO2 emissions',
    unit: 'tons/ha',
    lowEmission: 1315,
    highPerformance: 1795,
    currentValue: 1500,
  },
];

const KPIRangeTable: React.FC<KPIRangeTableProps> = () => {
  return (
    <div style={{ width: '100%' }}>
      <img src="/mock-images/siom.png" alt="SIOM Calculation - Indicative scenario evaluation" style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default KPIRangeTable;
