import React from 'react';
import { Card, Progress, Typography, Space, Tag, Statistic } from 'antd';
import { CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

interface SurvivalChanceIndicatorProps {
  chance: number;
  cropName?: string;
  showDetails?: boolean;
}

const SurvivalChanceIndicator: React.FC<SurvivalChanceIndicatorProps> = ({ chance, cropName = 'Crop', showDetails = true }) => {
  // Valideer de input
  const validChance = Math.max(0, Math.min(100, chance));

  // Bepaal de status
  const getStatus = (value: number) => {
    if (value >= 70) {
      return {
        color: '#52c41a',
        text: 'Excellent',
        icon: <CheckCircleOutlined />,
        status: 'success',
      };
    }
    if (value >= 40) {
      return {
        color: '#faad14',
        text: 'Moderate',
        icon: <WarningOutlined />,
        status: 'warning',
      };
    }
    return {
      color: '#f5222d',
      text: 'Critical',
      icon: <CloseCircleOutlined />,
      status: 'exception',
    };
  };

  const status = getStatus(validChance);

  return (
    <Card
      hoverable
      style={{
        width: '100%',
      }}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {/* Header */}
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Title level={4} style={{ margin: 0 }}>
            {cropName}
          </Title>
          <Tag color={status.color} icon={status.icon}>
            {status.text}
          </Tag>
        </Space>

        {/* Progress indicator */}
        <Progress
          percent={validChance}
          status={status.status as 'success' | 'warning' | 'exception'}
          strokeColor={{
            '0%': status.color,
            '100%': status.color,
          }}
          strokeWidth={12}
        />

        {/* Statistics */}
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Statistic title="Survival Chance" value={validChance} suffix="%" valueStyle={{ color: status.color }} />
          {showDetails && (
            <Space direction="vertical" size="small" style={{ textAlign: 'right' }}>
              <div>
                <Text type="secondary">Minimum required: </Text>
                <Text strong>40%</Text>
              </div>
              <div>
                <Text type="secondary">Optimal from: </Text>
                <Text strong>70%</Text>
              </div>
            </Space>
          )}
        </Space>
      </Space>
    </Card>
  );
};

export default SurvivalChanceIndicator;
